/* Custom styles for the custom-div class */
.allocate-project-custom-div {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.allocate-project-custom-div select,
.allocate-project-custom-div input {
    /* padding: 10px;  */
    border: 1px solid #ccc;
    /* border-radius: 4px; */
}

.allocate-project-custom-div .ant-select-selection {
    border: none;
}

.allocate-project-field {
    text-align: start;
    width: 267px;
    height: 32px;
}

.allocate-project-custom-div textarea {
    height: 75px;
    resize: none;
}

.allocate-project-custom-button {
    display: flex;
    justify-content: flex-end;
    position: relative;
}

.allocate-project-label {
    display: flex;
    margin-left: 10px;
}

.allocate-project-custom-button button {
    width: 120px;
    height: 36px;
}

.allocate-project-section {
    display: flex;
    justify-content: space-between;
}

.allocate-project-error-message {
    color: red;
    font-size: 13px;
    min-height: 30px;
    width: 240px;
    padding: 5px;
    text-align: left;
}