.container {
    gap: 50px;
    display: flex;
    /* width: 892px;
    height: 820px; */
}

.checkbox {
    display: flex;
    /* justify-content: start; */
    /* text-align: start; */
    margin-top: 10px;
    margin-bottom: 25.93px;
}

.profile-picture {
    display: flex;
    /* margin-left: 190px; */
    margin-bottom: 50px;
    flex-direction: column;
    justify-content: flex-end;
    text-align: center;
}

.creates-button {
    background-color: #40A9FF;
    color: white;
    height: 36.62px;
    display: flex;
    height: 36.121px;
    text-align: center;
    border-radius: 2px;
}

/* .inputtext{
    height: 45px;
    border-radius: 2px;

} */

title {
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
}

.camera-image {
    display: flex;
    width: 35px;
    margin-left: 71px;
    /* display: flex; */
    /* margin-top: -24px; */
    border: 1px solid #EBEBEB;
    border-radius: 50px;
    justify-content: center;
}