.rate_form {
    font-size: 14px;
    font-weight: 500;
    text-align: left;
    color: rgb(0, 0, 0);
    display: flex;
    flex-direction: column;
    padding-top: 30px;
}

/* .custom-slider {
    width: 530px;
    height: 50px;
} */