/* Custom styles for the custom-div class */
.create-task-custom-div {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.create-task-custom-div select,
.create-task-custom-div input {
  padding: 10px; 
  border: 1px solid #ccc; 
  border-radius: 4px;
}

.create-task-custom-div .ant-select-selection {
  border: none; 
}
.create-task-field{
  text-align: start;
  width: 267px;
  height: 32px;
}
.create-task-custom-discription{
  display: flex;
  flex-direction: column;
  /* padding: 0px 46px; */
  gap: 5px;
  margin-left: 3px;
  resize: none;
}

.create-task-custom-button{
  display: flex;
  justify-content: flex-end;
  position: relative;
  /* right: 45px; */
}
.create-task-label{
  display: flex;
  margin-left: 10px;
}

.create-task-custom-discription textarea{
  /* width: 706px; */
  height: 75px;
}

.create-task-custom-button button{
  width: 120px;
  height: 36px;
}
.create-task-section{
  display: flex;
  justify-content: space-between;
}
