.profile-maincontainer {
    position: relative;
    display: flex;
    flex-direction: column;
    background-image: url("./cover.jpg");
    background-size: 100% 230px;
    background-repeat: no-repeat;
    max-width: calc(100vw - 220px);
    height: 100vh;
    overflow-y: auto;
}

.change-cover {
    position: absolute;
    width: 200px;
    height: 40px;
    background-color: rgb(34, 34, 244, 0.5);
    top: 20px;
    right: 20px;
    border: 1px solid white;
}

.change-cover-text {
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    color: #fff;
    font-size: 16px;
    margin-top: 5px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-left: 20px;
}

.profile-maincontainer-subsection {
    /* display: flex;
    flex-direction: row; */
    display: flex;
    flex-grow: 1;
}

.profile-maincontainer-sidebar {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-width: 250px;
    width: 250px;
    height: calc(100vh - 220px);
    border: 1px solid #ebebeb;
    margin-left: 30px;
    margin-right: 30px;
    margin-top: 110px;
    background-color: #fff;

    flex: 0 0 250px;
    overflow-y: auto;
}

.profile-maincontainer-sidebar-title {
    display: flex;
    flex-direction: column;
    position: relative;
    align-items: center;
    justify-content: center;
    height: 200px;
    width: 260px;
    margin-bottom: 20px;
}

.Profile-pic {
    display: flex;
    margin-top: 50px;
    height: 100px;
    width: 100px;
}

.profile-name {
    font-size: 20px;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-weight: 400;
    margin: 0px;

}

.profile-designation {
    font-size: 15px;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-weight: 300;
    margin-top: 0px;
}

.profile-maincontainer-details {
    margin-top: 110px;
    width: calc(100vw - 550px);
    height: calc(100vh - 220px);
    /* border: 1px solid #ebebeb; */
    margin-right: 30px;
    background-color: #fff;

    flex-grow: 1;
    overflow-y: auto;
}

.profile-rate-tab {
    display: flex;
    flex-direction: row;
    border-bottom: 1px solid #ebebeb;
    border-top: 1px solid #ebebeb;
    width: 297px;
    height: 70px;
    align-items: center;

}

.profile-rate-tab-text {
    margin-right: 5px;
    font-size: 20px;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-weight: 300;
    margin-left: 40px;
}

.profile-rate-tab-star {
    margin-left: 2px;
    margin-top: 7px;
}


.profile-completetask {
    display: flex;
    flex-direction: row;
    width: 297px;
    height: 70px;
    align-items: center;

}

.profile-completetask-text {
    font-size: 18px;
    line-height: 18px;
    font-weight: 400;
    margin-left: 40px;
    margin-right: 30px;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.profile-completetask-value {
    color: rgba(0, 128, 0, 1);
    margin-left: 18px;
}

.profile-pendingtask {
    display: flex;
    flex-direction: row;
    border-bottom: 1px solid #ebebeb;
    border-top: 1px solid #ebebeb;
    width: 297px;
    height: 70px;
    align-items: center;
}

.profile-pendingtask-text {
    font-size: 18px;
    line-height: 18px;
    font-weight: 400;
    margin-left: 40px;
    margin-right: 50px;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.profile-pendingtask-value {
    color: rgba(255, 165, 0, 1);
    margin-left: 20px;
}