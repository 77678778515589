/* .Employee-Table-field{
    padding: 15px 20px 30px;
}
.employee-page-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 70px;
}
.employee-page-title-container {
    width: 50%;
    margin-right: 40px;

}
.employee-table{
    padding: 0px 20px;
    background: #ECECF0D9;
}
.employee-main-container{
    padding: 15px 20px 30px;
}
.task-export-container{
    display: flex;
    flex-direction: column;
}  */

.employee-main-container {
  /* border: 1px solid #EBEBEB; */
  background: #ececf0d9;
}

.employee-table-field {
  padding: 8px 20px 8px;
  display: flex;
}

.employee-page-title-container {
  width: 50%;
  margin-right: 40px;
}

.employee-table {
  padding: 0px 20px;
  background: #ececf0d9;
}

.employee-page-button {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 20px;
  padding-left: 20px;
  padding-right: 20px;
}

.employee-search-input {
  width: 400px;
  margin-right: 30px;
}
.employee-table {
  td {
    padding: 10px;
  }
}
.employee-table,
td {
  padding: 10px;
}

.employee-table {
  tr:nth-child(odd) {
    background-color: #fff;
    /* color: #999; */
  }
  td {
    font-size: 12px;
  }
  th {
    padding: 15px;
    font-size: 14px;
  }
}
