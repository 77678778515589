.empprofiledata {
    display: flex;
    flex-direction: column;
    margin-top: 5px;
    /* border: 1px solid #d1c5c569;
    height: calc(100vh - 270px); */
}

.empprofiledetails {
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid #ebebeb;


}

.empprofiledata-section1 {
    display: flex;
    flex-direction: row;
    gap: 3rem;
    margin-bottom: 30px;
}

.detail {
    display: flex;
    flex-direction: column;
    width: 361.5px;
    height: 35px;
    padding: 0px, 32px, 0px, 0px;
    gap: 1px;
    margin-left: 50px;
}

.detail-label {
    /* width: 100px; */
    height: 20px;
    line-height: 20px;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-weight: 400;
    font-size: 14px;
    color: rgba(101, 110, 129, 1);
}

.detail-value {
    width: 329.5px;
    height: 23px;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 23px;
    letter-spacing: 0px;
    text-align: left;
}



.empprofiledata-section2 {
    display: flex;
    flex-direction: row;
    gap: 3rem;
    margin-bottom: 30px;
}



.empprofiledata-section3 {
    display: flex;
    flex-direction: row;
    gap: 3rem;
    margin-bottom: 30px;
}




.empprofiledata-section4 {
    display: flex;
    flex-direction: row;
    gap: 3rem;
    margin-bottom: 30px;
}


.empprofiledata-section4-totalexperiencedata {
    width: 329.5px;
    height: 23px;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 23px;
    letter-spacing: 0px;
    text-align: left;
}

.employeecontactdetails {
    display: flex;
    flex-direction: column;
}

.contactdetaills-title {
    width: 329.5px;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-weight: 400;
    font-size: 18px;
    height: 22px;
    top: 667px;
    left: 636px;
    margin-left: 50px;

}

.contactdetails {
    display: flex;
    flex-direction: row;
    gap: 3rem;
    margin-bottom: 30px;
    color: rgba(0, 0, 0, 1);

}

.custom-date-picker {
    width: 300px;
    height: 23px;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 23px;
    letter-spacing: 0px;
    text-align: left;
    padding-left: 0px;
}

.employeprofile-update-button {
    height: 36.12px;
    background: #40A9FF;
    border-radius: inherit;
    width: 120px;
    left: 69px;
    top: 66px;
}

.employeprofile-edit-button {
    height: 36.12px;
    background: #40A9FF;
    border-radius: inherit;
    width: 120px;
    text-align: end;
}

.employeprofile-editbutton {
    text-align: end;
    padding-top: 30px;
    padding-right: 50px;
}