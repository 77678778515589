.li-project-option {
    padding: 10px 0px;
    padding-left: 10px;
    border: 1px solid #f0f0f0;
    border-bottom: 0px;
    cursor: pointer;
}

.li-project-option:hover {
    background-color: #f5f5f5;
}

.li-project-option:first-child {
    border-radius: 5px 5px 0px 0px;
}

.li-project-option:last-child {
    border-radius: 0px 0px 5px 5px;
}