body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.page-container {
  flex: 1;
  /* border: 1px solid red; */
}
.page-v-split {
  display: flex;
  overflow: hidden;
}
.page-body {
  padding: 10px;
  flex: 1;
  overflow: hidden;
}
