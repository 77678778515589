.suggestion_form {
    font-size: 14px;
    font-weight: 500;
    text-align: left;
    color: rgb(0, 0, 0);
    padding-left: 75px;
    padding-right: 75px;
    display: flex;
    flex-direction: column;
}
.suggestion-form-header-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 85px;
}
.usica-suggestion-save-button{
    display: flex;
    width: 114px;
    height: 40px;
    justify-content: center;
    align-items: center
}