/* .quote_list_searchbox_container {
    display: flex;
    gap: 80px;
    padding-inline: 20px;
    padding-top: 10px;
    padding-bottom: 30px;
} */

.role-table-field{
    padding: 15px 20px 30px;
}

.role-page-title-container {
    width: 50%;
    margin-right: 40px;

}
.role-table{
    padding: 0px 20px;
    /* background: #ECECF0D9; */
}

.role-page-button {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 20px;
    padding-left: 20px;
    padding-right: 20px;
}

.role-search-input{
    width: 400px;
    margin-right: 30px;
}