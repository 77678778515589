
/* Custom styles for the custom-div class */
.create-project-custom-div {
    display: flex;
    flex-direction: column;
    gap: 5px;
  }
  
  .create-project-custom-div select,
  .create-project-custom-div input {
    padding: 10px; 
    border: 1px solid #ccc; 
    border-radius: 4px;
  }
  
  .create-project-custom-div .ant-select-selection {
    border: none; 
  }
  .create-project-field{
    text-align: start;
    width: 267px;
    height: 32px;
  }
  .create-project-custom-discription{
    display: flex;
    flex-direction: column;
    gap: 5px;
    resize: none;
  }
  
  .create-project-custom-button{
    display: flex;
    justify-content: flex-end;
    position: relative;
  }
  .create-project-label{
    display: flex;
    margin-left: 10px;
  }
  
  .create-project-custom-discription textarea{
    height: 75px;
  }
  
  .create-project-custom-button button{
    width: 120px;
    height: 36px;
  }
  
  .create-project-section{
    display: flex;
    justify-content: space-between;
  }
  .create-project-error-message{
    color: red;
    font-size: 13px;
    min-height: 30px;
    width: 240px;
    padding: 5px;
    text-align: left;

}

.create-project-error-message-description{
    color: red;
    font-size: 13px;
    min-height: 30px;
    padding: 5px;
    text-align: left;
}
    