.allocate-work-section {
    margin-bottom: 20px;
}

.allocate-work-custom-div {
    display: flex;
    flex-direction: column;
}

.allocate-work-label {
    display: flex;
    margin-right: 10px;
    margin-left: 10px;
    font-size: 15px;
}

.allocate-work-field {
    text-align: start;
    width: 267px;
    height: 32px;
    color: black;
}

.allocate-work-button {
    height: 40px;
}

.allocate-work-error-message {
    color: red;
    font-size: 14px;
    margin-top: 5px;
}

/* Add additional styles as needed */