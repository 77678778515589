.project-main-container {
  /* border: 1px solid #EBEBEB; */
  background: #ececf0d9;
  flex: 1;
}

.projects-table-field {
  padding: 15px 20px 30px;
}

.project-page-title-container {
  width: 50%;
  margin-right: 40px;
}
.project-table {
  padding: 0px 20px;
  /* background: #ECECF0D9; */
}

.project-page-button {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 20px;
  padding-left: 20px;
  padding-right: 20px;
}

.project-search-input {
  width: 400px;
  margin-right: 30px;
}

.project-sub-menu {
  margin-bottom: 10px;
  color: #666;
  font-size: 18;
  letter-spacing: 0.5px;
  cursor: pointer;
  color: #314770;
}

.order-ticket-fund-btn {
  padding: 8px 8px;
  font-size: 15px;
  background: transparent;
  border-radius: 10px;
  border: 2px solid #bdbbbb;
}

.order-ticket-select-btn {
  padding: 8px 45px;
  font-size: 15px;
  border-radius: 10px;
  border: none;
  background: #ff5722;
  color: aliceblue;
}

.order-ticket-container {
  background: #fff;
  padding: 5px;
}
