.achievement_form {
    font-size: 14px;
    font-weight: 500;
    text-align: left;
    color: rgb(0, 0, 0);
    padding-left: 40px;
    padding-right: 40px;
    display: flex;
    flex-direction: column;
}


.achievement_form_header_item {
    display: flex;
    align-items: center;
    gap: 50px;
}