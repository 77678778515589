.usica-newlogin-section{
    width: 1090px;
    display: flex;
    justify-content: center;
    /* border: 1px solid #EBEBEB; */
    box-shadow:0 0 10px rgba(0, 0, 0, 0.5);
}

.usica-newlogin-header{
    width: 545px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-image: linear-gradient( 109.6deg,rgba(110,123,251,1) 11.2%,rgba(156,252,248,1) 91.1% );
}

.usica-newlogin-title{
    font-family: Montserrat;
    font-size: 32px;
    color: #fff;
}

.usica-newlogin-form-container{
    width: 545px;
    margin-top: 30px;
    margin-bottom: 100px;

}

.usica-newlogin-form{
    display: flex;
    justify-content: center;
}



.usica-newlogin-form-title{
    font-size: 32px;
    font-family: Montserrat;
    color: #1c1c43;
    letter-spacing: 0px;
}
.usica-newlogin-form-paragraph{
    font-size: 16px;
    font-family: Montserrat;
    color: gray;
    width: 330px;
}


.usica-newlogin-input-username{
    margin-top: 20px;
    height: 37px;
}

.usica-newlogin-input-password{
    margin: 20px 0px;
}

.usica-newlogin-forgot-password-container{
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
}

.usica-newlogin-button{
    margin-top: 20px;
}