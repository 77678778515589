.work-main-container {
    background: #ECECF0D9;
  }
  
  .work-table-field{
    padding: 15px 20px 30px;
}
  .work-page-button {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 20px;
    padding-left: 20px;
    padding-right: 20px;
  }
  
  .work-page-title-container {
    width: 50%;
    margin-right: 40px;
  }
  
  
  .work-search-input {
    width: 400px;
    margin-right: 30px;
  }
  
  .work-table {
    padding: 0px 20px;
  }

  