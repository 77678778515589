.role-form {
    font-size: 14px;
    font-weight: 500;
    text-align: left;
    color: rgb(0, 0, 0);
    padding-left: 75px;
    padding-right: 75px;
    display: flex;
    flex-direction: column;
}

.quote_form_search_title {
    color: rgb(0, 0, 0);
    text-align: left;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    padding-left: 30px;

}

.quote_form_header_item {
    display: flex;
    align-items: center;
    gap: 85px;
}

.cascader {
    border-radius: 2px;
}